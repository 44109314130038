.link-in-bio-intro {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.link-in-bio-intro h2 {
  font-size: 32px !important;
  font-weight: bold !important;
  color: #333 !important;
}

.link-in-bio-intro p {
  font-size: 18px !important;
  color: #666 !important;
}

.link-in-bio-intro ul {
  list-style-type: disc !important;
  margin-left: 20px !important;
}

.link-in-bio-intro ul li {
  font-size: 16px !important;
  color: #333 !important;
}
