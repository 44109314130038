/* ForgotPasswordPage.css */
body {
  background-color: #f8f9fa;
}

.otp-input-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.otp-input {
  width: 40px;
  height: 40px;
  font-size: 24px;
  text-align: center;
  margin-right: 10px;
}

.otp-input:last-child {
  margin-right: 0;
}

.bg-white {
  background-color: #ffffff;
}

.shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rounded {
  border-radius: 8px;
}

.min-vh-100 {
  min-height: 100vh;
}
